<template>
  <v-app-bar
      app
      :color="$route.query.insurance==='MPU' ? 'blue' : 'primary'"
      dark
  >
    <!--<v-btn @click="toggleDrawer" class="hidden-md-and-up" icon>
        <v-icon>mdi-menu</v-icon>
    </v-btn> -->
    <!--
    <v-btn @click="$router.back()" icon>
        <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    -->
    <div class="d-flex align-center">
      <v-img
          alt="STAG service"
          class="shrink mr-2"
          contain
          src="../../assets/example-logo.png"
          style="cursor: pointer"
          transition="scale-transition"
          width="40"
          v-on:click="$router.currentRoute.path === '/' ? '' : $router.push('/')"
      />
    </div>
    <v-btn v-if="showBack" text v-on:click="$router.push('/')">
      <v-icon>mdi-chevron-left</v-icon>
      Zurück
    </v-btn>
    <span v-if="$route.query.insurance==='MPU'">MPU Vorbereitungskurs</span>
    <span v-else>STAG Legal</span>

    <v-spacer/>
    <v-toolbar-items>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
              icon
              v-on="on"
          >
            <v-icon
            >
              mdi-web
            </v-icon>
          </v-btn>
        </template>
        <v-list v-model="$i18n.locale">
          <!--
          <v-list-item
                  :disabled="$i18n.locale === 'en'"
                  @click="setLang('en')"
          >
              <v-list-item-title>English</v-list-item-title>
          </v-list-item> -->
          <v-list-item
              :disabled="$i18n.locale === 'de'"
              @click="setLang('de')"
          >
            <v-list-item-title>Deutsch</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn icon @click="setTheme">
        <v-icon>mdi-invert-colors</v-icon>
      </v-btn>
        <v-btn @click="logout()" icon v-if="KeyCloakService.GetIsAuthenticated && this.$route.path !== '/upload/documents'"><v-icon>mdi-logout</v-icon></v-btn>
        <v-btn to="/" icon v-if="!KeyCloakService.GetIsAuthenticated && this.$route.path !== '/upload/documents'"><v-icon>mdi-login</v-icon></v-btn>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import {mapMutations} from 'vuex'
import KeyCloakService from "@/plugins/keycloak";

export default {
  name: "CoreAppBar",
  computed: {
    KeyCloakService() {
      return KeyCloakService
    }
  },
  data: () => ({
    showLogout: false,
    showBack: false
  }),
  methods: {
    ...mapMutations(['toggleDrawer']),
    setTheme() {
      if (this.$vuetify.theme.dark) {
        this.$RStore.app.DarkTheme = false;
        return (this.$vuetify.theme.dark = false);
      } else {
        this.$RStore.app.DarkTheme = true;
        return (this.$vuetify.theme.dark = true);
      }
    },
    getTheme() {
      this.$vuetify.theme.dark = this.$RStore.app.DarkTheme
    },
    setLang(lang) {
      //this.$vuetify.lang.current = lang;
      this.$i18n.locale = lang;
    },
    logout() {
        KeyCloakService.CallLogout();
    },
    checkRoute() {
      this.showBack = this.$router.currentRoute.path === '/documents'
      this.showLogout = this.$router.currentRoute.path !== "/login";
    }
  },
  created() {
    this.getTheme();
    this.checkRoute();
  },
  watch: {
    $route() {
      this.checkRoute();
    }
  }

}
</script>

<style scoped>

</style>
